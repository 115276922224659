import { createUseStyles } from "react-jss";

const ThatsHer = (props) => {
  const classes = useStyles();
  return (
      <section className={classes.page}>
        <div className={classes.bio}>
          <h5>That's her</h5>
          <p>
            She arrived like a season, <br /> 
            her presence <br />
            brought warmth <br />
            brought lightness <br />
            brought compassion <br />
          </p>
          <p>
          And I say, "that's her" <br />
          </p>
          <p>
          Like a meadow, <br />
          the closer I got,  <br />
          the more the beauty of her being came into focus. <br />
          Her presence; comfort, <br />
          laying in the embrace of a soft windswept field. <br />
          She was Spring eternal. <br />
          </p>
          <p>
          And I say, "that's her" <br />
          </p>
          <p>
          She lives in crossroads <br />
          Exploring what it is to chart a future course <br />
          Learning how to trust in the power of her being <br />
          In the overwhelm of it all, <br />
          she has managed with grace. <br />
          </p>
          <p>
          And I say, "that's her" <br />
          </p>
          <p>
          She's the cutest movies <br />
          She's an orange cat named Calcifer <br />
          She's the most sophisticated & inviting interior design <br />
          She's (likely) a secretive cosplay obsession (very cool) <br />
          She's a rosetta on a latte <br />
          She's a beautiful pair of stained glass earings <br />
          She's two cute raccoons swimming in a circle chasing each other's tails <br />
          She's the most beautiful sense of style <br />
          She's an emotional focus session at the gym <br />
          She's a projector watching ghibli films in bed <br />
          She's two labrador retrievers <br />
          She's the richest chocolate eyes <br />
          She's little shots of soju with some kind of yogurt <br />
          She's a keychain of her grandmother <br />
          She's a still unknown favorite coffee order <br />
          She's multiple rock paper scissor games won <br />
          She's a travel companion <br />
          She's a couple's cosplay for halloween <br />
          She's a beautiful smile <br />
          She's linen bed sheets and a fluffy duvet <br />
          She's a move to another country <br />
          She's Rose, without liking roses <br />
          She's a peony in the garden <br />
          She's a morning taking turns making each other lattes <br />
          She's a ticket for two to Ghibli Park <br /> 
          She's an explorer of the Chamorro and German cross-section of heritage <br />
          She's so much more <br />
          </p>
          <p>
          and there's a real chance that I'll never know <br />
          </p>
          <p>
          And I say, "that's her" <br />
          </p>
        </div>
      </section>
  );
};

const useStyles = createUseStyles((theme) => ({
  page: {
    backgroundColor: theme.colorSecondary,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    minHeight: "80vh",
    maxWidth: "100vw",
    color: theme.colorPrimary,
    transition: [["background-color", "0.2s", "ease"]],
    "& > *": {
      marginLeft: "1rem",
      marginBottom: "3vh",
    },
    "& h2": {
      marginTop: "0",
      marginBottom: 0,
    },
    "&  h3": {
      marginTop: 0,
      marginBottom: 0,
    },
    "&  p": {
      marginTop: 12,
      marginBottom: 16,
      marginRight: "0.25rem",
    },
    "& > p:last-of-type": {
      minWidth: "90vw",
      minHeight: "3rem",
      marginRight: "1rem",
      marginBottom: "3vh",
      marginTop: 0,
      padding: "0.25rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      borderRadius: 10,
      backgroundColor: theme.colorAccent,
      color: theme.colorDarkest,
    },
  },
  bio: {
    animation: "$fadeIn ease 3s",
    animationIterationCount: 1,
    animationFillMode: "forwards",
    "& > hr": {
      marginRight: "1rem",
      marginLeft: 0,
      borderTop: [[2, "solid", theme.colorAccent]],
    },
    "& > p": {
      fontSize: "0.75rem",
      marginBelow: "3rem",
    },
  },
  story: {},
  header: {
    display: "flex",
    alignItems: "center",
    marginTop: "5vh",
    "& > h2": {
      marginRight: "auto",
    },
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: "0",
    },
    "100%": {
      opacity: "1",
    },
  },
  hobbies: {
    display: "flex",
    "& > div > svg": {
      width: "50px",
      marginLeft: "0.5rem",
      "& > g > path:first-of-type": {
        fill: [[theme.colorAccent], "!important"],
      },
    },
    "& > div > svg:last-of-type": {
      marginRight: "1rem",
    },
  },
  "@media only screen and (min-width: 768px)": {
    page: {
      minHeight: "60vh",
      flexDirection: "row",
      flexFlow: "wrap",
      "& > p:last-of-type": {
        fontSize: "1.5rem",
        fontWeight: "500",
      },
    },
    bio: {
      width: "45vw",
    },
    story: {
      marginTop: "5vh",
      width: "45vw",
    },
  },
}));

export default ThatsHer;
