import { createUseStyles } from "react-jss";

const FriendOrGuide = (props) => {
  const classes = useStyles();
  return (
      <section className={classes.page}>
        <div className={classes.bio}>
          <h5>Friend or Guide</h5>
          <p>
            The best we can be to each other is not a guide, but a friend.  < br/>
          </p>
          <p>
            Sit with me in my sorrows.  < br/>
            Hug me when I feel the bitter chill of loneliness. < br/>
            If I do not ask, do not lecture me on how to make this journey. < br/>
          </p>
          <p>
            I know the way, I only need the energy. < br/>
          </p>
          
        </div>
      </section>
  );
};

const useStyles = createUseStyles((theme) => ({
  page: {
    backgroundColor: theme.colorSecondary,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    minHeight: "80vh",
    maxWidth: "100vw",
    color: theme.colorPrimary,
    transition: [["background-color", "0.2s", "ease"]],
    "& > *": {
      marginLeft: "1rem",
      marginBottom: "3vh",
    },
    "& h2": {
      marginTop: "0",
      marginBottom: 0,
    },
    "&  h3": {
      marginTop: 0,
      marginBottom: 0,
    },
    "&  p": {
      marginTop: 12,
      marginBottom: 16,
      marginRight: "0.25rem",
    },
    "& > p:last-of-type": {
      minWidth: "90vw",
      minHeight: "3rem",
      marginRight: "1rem",
      marginBottom: "3vh",
      marginTop: 0,
      padding: "0.25rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      borderRadius: 10,
      backgroundColor: theme.colorAccent,
      color: theme.colorDarkest,
    },
  },
  bio: {
    animation: "$fadeIn ease 3s",
    animationIterationCount: 1,
    animationFillMode: "forwards",
    "& > hr": {
      marginRight: "1rem",
      marginLeft: 0,
      borderTop: [[2, "solid", theme.colorAccent]],
    },
    "& > p": {
      fontSize: "0.75rem",
      marginBelow: "3rem",
    },
  },
  story: {},
  header: {
    display: "flex",
    alignItems: "center",
    marginTop: "5vh",
    "& > h2": {
      marginRight: "auto",
    },
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: "0",
    },
    "100%": {
      opacity: "1",
    },
  },
  hobbies: {
    display: "flex",
    "& > div > svg": {
      width: "50px",
      marginLeft: "0.5rem",
      "& > g > path:first-of-type": {
        fill: [[theme.colorAccent], "!important"],
      },
    },
    "& > div > svg:last-of-type": {
      marginRight: "1rem",
    },
  },
  "@media only screen and (min-width: 768px)": {
    page: {
      minHeight: "60vh",
      flexDirection: "row",
      flexFlow: "wrap",
      "& > p:last-of-type": {
        fontSize: "1.5rem",
        fontWeight: "500",
      },
    },
    bio: {
      width: "45vw",
    },
    story: {
      marginTop: "5vh",
      width: "45vw",
    },
  },
}));

export default FriendOrGuide;
