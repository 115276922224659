import "./App.css";
import ThemeSelector from "./components/Utility/ThemeSelector";
import ThemeToggler from "./components/Buttons/ThemeToggler";
import Landing from "./components/Landing";
import About from "./components/About";
import Experience from "./components/Experience";
import Projects from "./components/Projects";
import { createUseStyles } from "react-jss";
import APECS from "./components/APECS";
import { Routes, Route } from "react-router-dom";
import ThatsHer from "./components/Prose/ThatsHer";
import FriendOrGuide from "./components/Prose/FriendOrGuide";


function App() {
  const classes = useStyles();
  return (
    <Routes>
        <Route index element={<ThemeSelector>
        <div className={classes.singlePage}>
          <Landing />
          <About />
          <Experience />
          <APECS />
          <Projects />
          <ThemeToggler />
        </div>
      </ThemeSelector>} />
        <Route path="thatsher" element={<ThatsHer />} />
        <Route path="friendorguide" element={<FriendOrGuide />} />
    </Routes>
  );
}

const useStyles = createUseStyles((theme) => ({
  singlePage: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
}));

export default App;
